<template>
  <v-container fluid>

    <!--Data table-->
    <data-table 
    :headers="headers"
    :data="customerInvoices"
    :loading="loading"
    :pageNumber="invoicesPageNumber"
    :paginate="paginate"
    :options="options"
    >
        <components :is="headerChildComponent" :refreshData="handleRefreshData"></components>
    </data-table>

  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { itemsSpan } from '../../helpers/index';
import DataTable from '../common/DataTable.vue';
import DataTableHeader from '../common/DataTableHeader.vue';
export default {
    components:{
      DataTable,
      DataTableHeader
    },
    data(){
        return{
            userId: this.$route.params.id,
            headers: [
                { text: 'Created at', value: 'created' },
                { text: 'Status', value: 'status' },
                { text: 'Billing reason', value: 'billing_reason' },
                { text: 'Collection method', value: 'collection_method' },
                { text: 'Amount due', value: 'amount_due' },
                { text: 'Amount paid', value: 'amount_paid' },
                { text: 'Currency', value: 'currency' },
                { text: 'Customer email', value: 'customer_email' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            headerChildComponent: 'DataTableHeader',
            options:{
            timestamps: [ 'created' ],
            actions: [
                { color: 'primary', hrefName: 'invoice_pdf', icon: 'mdi-cloud-download' },
                { color: 'primary', hrefName: 'hosted_invoice_url', icon: 'mdi-open-in-new' }
            ]
            }
        }
    },
    computed: {
        ...mapState('user', ['customerInvoices', 'invoicesPageNumber', 'loading']),
        //Calculation for table footer data
        itemsSpan,
        //Vuex getters and setters for computed property
        userInvoicesPageNumber: {
            get () {
                return this.invoicesPageNumber
            },
            set (value) {
                this.UPDATE_INVOICES_PAGE_NUMBER(value)
            }
        }
    },
    methods: {
      ...mapActions('user', ['getCustomerInvoices']),
      ...mapMutations('user', ['UPDATE_INVOICES_PAGE_NUMBER']),
      //Table pagination
      paginate(pageNumber){

      //Updating page number to vuex module
      this.userInvoicesPageNumber = pageNumber

      //Requesting new data with new pageNumber
      this.refreshData();

      },
      handleRefreshData(){
          this.UPDATE_INVOICES_PAGE_NUMBER(1)
          this.refreshData()
      },
      refreshData(){
          this.getCustomerInvoices({ userId: this.userId });
      }
    },
    created(){

        //Stripe customer object needed for getting subscriptions
        // Getting customer from user id
        this.refreshData()
    }
}
</script>

<style>

</style>