<template>
  <v-container fluid>

    <!--Table data-->
    <data-table 
    :headers="headers"
    :data="customerSubscriptions"
    :loading="loading"
    :pageNumber="subscriptionsPageNumber"
    :paginate="paginate"
    :options="options"
    >
      <component :is="headerChildComponent" :refreshData="handleRefreshData"></component>
    </data-table>
      
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { itemsSpan } from '../../helpers/index'
import DataTable from '../common/DataTable.vue'
import DataTableHeader from '../common/DataTableHeader.vue'
export default {
    components:{
      DataTable,
      DataTableHeader
    },
    data(){
        return{
          userId: this.$route.params.id,
          headers: [
              { text: 'Created at', value: 'created' },
              { text: 'Status', value: 'status' },
              { text: 'Current period start', value: 'current_period_start' },
              { text: 'Current period end', value: 'current_period_end' },
              { text: 'Collection method', value: 'collection_method' },
              { text: 'Plan', value: 'plan_id' }
          ],
          headerChildComponent: 'DataTableHeader',
          options:{
            statusChips: ['status'],
            timestamps: [ 'created', 'current_period_start', 'current_period_end' ]
          }
        }
    },
    computed: {
        ...mapState('user', ['customerSubscriptions', 'subscriptionsPageNumber', 'loading']),
        //Calculation for table footer data
        itemsSpan,
        //Vuex getters and setters for computed property
        userSubscriptionsPageNumber: {
            get () {
                return this.subscriptionsPageNumber
            },
            set (value) {
                this.UPDATE_SUBSCRIPTIONS_PAGE_NUMBER(value)
            }
        }
    },
    methods: {
      ...mapActions('user', ['getCustomerSubscriptions']),
      ...mapMutations('user', ['UPDATE_SUBSCRIPTIONS_PAGE_NUMBER']),
      //Table pagination
      paginate(pageNumber){

      //Updating page number to vuex module
      this.userSubscriptionsPageNumber = pageNumber

      //Requesting new data with new pageNumber
      this.refreshData()

      },
      handleRefreshData(){
        this.UPDATE_SUBSCRIPTIONS_PAGE_NUMBER(1)
        this.refreshData()
      },
      refreshData(){
        this.getCustomerSubscriptions({ userId: this.userId });
      }
    },
    created(){

        //Stripe customer object needed for getting subscriptions
        // Getting customer from user id
        this.refreshData()
    }
}
</script>

<style>

</style>