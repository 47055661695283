<template>
<v-container class="d-flex justify-center bg-color" fluid>
        <v-container class="mt-3" fluid>
           <!--Breadcrumbs--> 
           <Breadcrumbs :breadcrumbs="breadcrumbs" />
            
            <v-container class="main-wrapper" fluid>
                <!--User card--> 
                    <v-card class="d-flex jusitfy-space-evenly pa-3">
                        <v-container class="d-flex justify-center">
                            <v-tabs>
                                <v-tab>
                                    Contact Info
                                </v-tab>
                                <v-tab-item>
                                    <ContactInfo />
                                </v-tab-item>

                                <v-tab>
                                    Reports
                                </v-tab>
                                <v-tab-item>
                                    <Reports />
                                </v-tab-item>

                                <v-tab>
                                    Articles
                                </v-tab>
                                <v-tab-item>
                                    <Articles />
                                </v-tab-item>

                                <v-tab>
                                    Invoices
                                </v-tab>
                                <v-tab-item>
                                    <Invoices />
                                </v-tab-item>

                                <v-tab>
                                    Subscriptions
                                </v-tab>
                                <v-tab-item>
                                    <Subscriptions />
                                </v-tab-item>
                            </v-tabs>
                        </v-container>
                    </v-card>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
import ContactInfo from '../components/user/ContactInfo.vue'
import Reports from '../components/user/Reports.vue'
import Articles from '../components/user/Articles.vue'
import Invoices from '../components/user/Invoices.vue'
import Subscriptions from '../components/user/Subscriptions.vue'
import Breadcrumbs from '../components/common/Breadcrumbs.vue'
export default {
    name: 'User',
    components: {
        Breadcrumbs,
        ContactInfo,
        Reports,
        Articles,
        Invoices,
        Subscriptions
    },
    data(){
        return{
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    href: '/',
                },
                {
                    text: 'Users',
                    href: '/users',
                },
                {
                    text: 'User',
                    href: '/users/1',
                },
            ],
            tab:null,
            tabs: [
                { tab: 'Contact Info', content: 'ContactInfo' },
                { tab: 'Reports', content: 'Reports' },
                { tab: 'Articles', content: 'Articles' },
                { tab: 'Invoices', content: 'Invoices' },
                { tab: 'Subscriptions', content: 'Subscriptions' }
            ]
        }
    }

}
</script>

<style scoped>
    .breadcrumb-link{
        color: #0000008a !important;
    }
</style>