<template>
  <v-container fluid>
    <data-table 
      :headers="headers"
      :data="userArticles"
      :loading="loading"
      :pageNumber="articlesPageNumber"
      :paginate="paginate"
      :options="options"
      >
        <component :is="headerChildComponent" :refreshData="handleRefreshData"></component>
      </data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { itemsSpan } from '../../helpers/index';
import DataTable from '../common/DataTable.vue';
import DataTableHeader from '../common/DataTableHeader.vue';
export default {
  components:{
    DataTable,
    DataTableHeader
  },
  data(){
    return{
      userId: this.$route.params.id,
        headers: [
            { text: 'Article name', value: 'name' },
            { text: 'Report keyword', value: 'keyword' },
            { text: 'Readability', value: 'readability' },
            { text: 'Word count', value: 'word_count' },
            { text: 'Content Grade', value: 'content_grade' },
        ],
        headerChildComponent: 'DataTableHeader',
        options:{
          contentGradeChips: ['content_grade']
        }
    }
  },
  computed: {
    ...mapState('user', ['userArticles', 'articlesPageNumber', 'loading']),
    itemsSpan,
    userArticlesPageNumber: {
        get () {
            return this.articlesPageNumber
        },
        set (value) {
            this.UPDATE_ARTICLES_PAGE_NUMBER(value)
        }
    }
  },
  methods:{
    ...mapActions('user', ['getUserArticles']),
    ...mapMutations('user', ['UPDATE_ARTICLES_PAGE_NUMBER']),
    paginate(pageNumber){

      this.userArticlesPageNumber = pageNumber

      this.refreshData()

    },
    handleRefreshData(){
      this.UPDATE_ARTICLES_PAGE_NUMBER(1)
      this.refreshData()
    },
    refreshData(){
      this.getUserArticles({ userId: this.userId })
    }
  },
  created(){
    this.refreshData()
  }
}
</script>