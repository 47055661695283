<template>
  <v-container class="contact-info">
    <v-row>
      <v-col class="d-flex flex-column align-center">
        <!--Left column-->
        <v-avatar size="200">
          <img :src="convertImageURL" alt="user" />
        </v-avatar>

        <v-container class="d-flex flex-column align-center">
          <h1 class="subtitle-1">Registered at:</h1>
          <p class="subtitle-2 font-weight-bold" style="margin-bottom: 0">
            {{
              this.user.date_registered !== null
                ? convertTimestamp(this.user.date_registered)
                : "No data"
            }}
          </p>
        </v-container>

        <v-container class="d-flex flex-column align-center">
          <h1 class="subtitle-1">Subscription:</h1>
          <p class="subtitle-2 font-weight-bold" style="margin-bottom: 0">
            {{
              this.user.user_plan !== null
                ? this.user.user_plan.description
                : "No chosen plan"
            }}
          </p>
        </v-container>

        <v-container class="d-flex flex-column align-center">
          <h1 class="subtitle-1">Reports remaining:</h1>
          <p class="subtitle-2 font-weight-bold">
            {{ this.user.remaining_reports }}
          </p>
        </v-container>
      </v-col>

      <!--Right column-->
      <v-col>
        <v-form>
          <label for="name">Name</label>
          <v-text-field v-model="name" class="mt-2" type="text" outlined>
          </v-text-field>

          <v-row>
            <v-col>
              <label for="is_business">Is business</label>
              <v-select
                class="mt-2"
                :items="selectItems"
                v-model="is_business"
                outlined
              ></v-select>
            </v-col>

            <v-col>
              <label for="remaining_reports">Remaining reports</label>
              <v-text-field
                class="mt-2"
                v-model="remaining_reports"
                :outlined="true"
                type="number"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <label for="is_affiliate">Is affiliate</label>
              <v-select
                class="mt-2"
                :items="selectItems"
                v-model="is_affiliate"
                outlined
              ></v-select>
            </v-col>

            <v-col>
              <label for="is_active">Is active</label>
              <v-select
                class="mt-2"
                :items="selectItems"
                v-model="is_active"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-container fluid class="text-center">
          <v-btn
            class="pa-5"
            color="secondary"
            large
            rounded
            :loading="loading"
            @click="handleSaveUser"
            min-width="200px"
            >Save</v-btn
          >
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { convertTimestamp } from "../../helpers/index";
export default {
  data() {
    return {
      selectItems: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["user", "loading"]),

    //Converting http to https in url of profile image
    convertImageURL() {
      const url = this.user.avatar;
      return url.replace(/^http:\/\//i, "https://");
    },

    //Convert boolean fto text (YES / NO)
    convertBoolean(boolean) {
      const booleanText = {
        true: "Yes",
        false: "No",
      };

      return booleanText[boolean.toString()];
    },

    //Binding inputs with vuex user state
    name: {
      get() {
        return this.user.name;
      },
      set(value) {
        this.setUserData("name", value);
      },
    },
    is_business: {
      get() {
        return this.user.is_business;
      },
      set(value) {
        this.setUserData("is_business", value);
      },
    },
    is_affiliate: {
      get() {
        return this.user.is_affiliate;
      },
      set(value) {
        this.setUserData("is_affiliate", value);
      },
    },
    is_active: {
      get() {
        return this.user.is_active;
      },
      set(value) {
        this.setUserData("is_active", value);
      },
    },
    remaining_reports: {
      get() {
        return this.user.remaining_reports;
      },
      set(value) {
        this.setUserData("remaining_reports", value);
      },
    },
  },
  methods: {
    ...mapActions("user", ["getUser", "saveUser"]),
    ...mapMutations("user", ["SET_USER"]),
    convertTimestamp,

    //Save user data from inputs to vuex user state
    setUserData(key, value) {
      const user = { ...this.user };
      user[key] = value;

      this.SET_USER(user);
    },
    handleSaveUser() {
      this.saveUser(this.$route.params.id);
    },
  },
  created() {
    //Fetch user data with ID from URL
    this.getUser(this.$route.params.id);
  },
};
</script>

<style scoped>
.contact-info {
  padding: 3em 1.5em;
}
</style>
