<template>
  <v-container fluid>

    <data-table 
      :headers="headers"
      :data="userReports"
      :loading="loading"
      :pageNumber="reportsPageNumber"
      :paginate="paginate"
      :options="options"
      >
        <component :is="headerChildComponent" :refreshData="handleRefreshData"></component>
      </data-table>
      
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import DataTable from '../common/DataTable.vue';
import DataTableHeader from '../common/DataTableHeader.vue';
import { itemsSpan } from '../../helpers/index';
export default {
  components:{
    DataTable,
    DataTableHeader
  },
  data(){
    return{
      userId: this.$route.params.id,
        headers: [
            { text: 'Report keyword', value: 'keyword' },
            { text: 'Country', value: 'country' },
            { text: 'Language', value: 'language' },
            { text: 'Status', value: 'status' },
        ],
        headerChildComponent: 'DataTableHeader',
        options: { statusChips: ['status'] }
    }
  },
  computed: {
    ...mapState('user', ['userReports', 'reportsPageNumber', 'loading']),
    itemsSpan,
    userReportsPageNumber: {
        get () {
            return this.reportsPageNumber
        },
        set (value) {
            this.UPDATE_REPORTS_PAGE_NUMBER(value)
        }
    }
  },
  methods:{
    ...mapActions('user', ['getUserReports']),
    ...mapMutations('user', ['UPDATE_REPORTS_PAGE_NUMBER']),
    paginate(reportsPageNumber){
      //console.log(val)

      this.userReportsPageNumber = reportsPageNumber

      this.refreshData()

    },
    handleRefreshData(){
      this.UPDATE_REPORTS_PAGE_NUMBER(1)
      this.refreshData()
    },
    refreshData(){
      this.getUserReports({ userId: this.userId })
    }
  },
  created(){
    this.refreshData()
  }
}
</script>

<style scoped>
.status-chip{
  display: grid;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  width: 120px;
}
.breadcrumb-link{
        color: #0000008a !important;
    }
</style>